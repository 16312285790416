.privacy-container {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 40px 20px;
}

.privacy-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.privacy-content h1 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.last-updated {
  text-align: center;
  color: #666;
  margin-bottom: 40px;
}

.privacy-content section {
  margin-bottom: 30px;
}

.privacy-content h2 {
  color: #2c3e50;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 2px solid #eee;
}

.privacy-content ul {
  padding-left: 20px;
  margin: 15px 0;
}

.privacy-content li {
  margin-bottom: 8px;
  line-height: 1.6;
}

.privacy-content p {
  line-height: 1.6;
  color: #444;
}

.back-to-home {
  margin-top: 40px;
  text-align: center;
}

.back-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.back-link:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .privacy-content {
    padding: 20px;
  }
} 