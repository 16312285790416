.landing {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero {
  text-align: center;
  padding: 60px 0;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 30px;
}

.features {
  padding: 60px 0;
}

.features h2,
.advantages h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
}

.features h2::after,
.advantages h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #007bff, #00a6ff);
  border-radius: 2px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cta {
  text-align: center;
  padding: 60px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 40px 0;
}

.cta-button {
  padding: 12px 30px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
} 

.language-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.language-select {
  padding: 12px 40px 12px 16px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease;
  min-width: 140px;
  color: #333;
}

.language-select:hover {
  border-color: rgba(0, 0, 0, 0.15);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.language-select:focus {
  outline: none;
  border-color: rgba(0, 123, 255, 0.4);
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

/* 美化选项样式 */
.language-select option {
  padding: 12px;
  font-size: 14px;
  background-color: white;
  color: #333;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .language-switcher {
    position: static;
    text-align: center;
    margin: 20px auto;
    padding: 0 20px;
  }
  
  .language-select {
    width: 100%;
    max-width: 200px;
    padding: 14px 44px 14px 20px;
  }
}

/* 深色模式支持 */
@media (prefers-color-scheme: dark) {
  .language-select {
    background-color: rgba(45, 45, 45, 0.9);
    color: #fff;
  }
  
  .language-select:hover {
    background-color: rgba(55, 55, 55, 0.95);
  }
  
  .language-select:focus {
    background-color: rgba(55, 55, 55, 1);
  }
  
  .language-select option {
    background-color: #2d2d2d;
    color: #fff;
  }
}

.screenshots {
  padding: 40px 0;
  overflow: hidden;
}

.screenshots-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;  /* Firefox */
  
  /* 隐藏默认滚动条 - Webkit browsers */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  /* 在深色模式下的滚动条样式 */
  @media (prefers-color-scheme: dark) {
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;  /* Firefox */
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

/* 可选：在移动端隐藏滚动条 */
@media (max-width: 768px) {
  .screenshots-container {
    scrollbar-width: none;  /* Firefox */
    
    &::-webkit-scrollbar {
      display: none;  /* Chrome, Safari, Edge */
    }
  }
}

.screenshot-item {
  flex: 0 0 300px;
  scroll-snap-align: start;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  padding: 10px;
}

.screenshot-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.screenshot-item:hover .screenshot-image {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .screenshot-item {
    flex: 0 0 250px;
  }
} 

.footer {
  text-align: center;
  padding: 20px 0;
  margin-top: 40px;
  border-top: 1px solid #eee;
}

.privacy-link {
  color: #666;
  text-decoration: none;
  transition: color 0.3s;
}

.privacy-link:hover {
  color: #007bff;
}

.feature-icon, .advantage-icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.advantages-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
}

.advantage-card {
  text-align: center;
  padding: 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s;
}

.advantage-card:hover {
  transform: translateY(-5px);
}

.cta-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.store-note {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .advantages-grid {
    grid-template-columns: 1fr;
  }
} 

.logo-container {
  margin-bottom: 30px;
  animation: fadeInDown 0.8s ease-out;
}

.app-logo {
  width: 120px;
  height: 120px;
  border-radius: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .app-logo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }
} 

.play-store-link {
  display: inline-block;
  transition: transform 0.3s ease;
}

.play-store-link:hover {
  transform: translateY(-2px);
}

.play-store-badge {
  height: 60px;
  width: auto;
  min-width: 160px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .play-store-badge {
    height: 48px;
    min-width: 140px;
  }
} 

/* 为阿拉伯语添加 RTL 支持 */
[dir="rtl"] {
  text-align: right;
}

/* 当语言为阿拉伯语时应用 RTL 样式 */
html[lang="ar"] .landing {
  direction: rtl;
}

html[lang="ar"] .language-switcher {
  left: 20px;
  right: auto;
}

html[lang="ar"] .feature-card,
html[lang="ar"] .advantage-card {
  text-align: right;
}

.hero-rating {
  font-size: 1.2rem;
  color: #666;
  margin: 15px 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media (prefers-color-scheme: dark) {
  .hero-rating {
    color: #aaa;
  }
}

@media (max-width: 768px) {
  .hero-rating {
    font-size: 1.1rem;
    margin: 12px 0 20px;
  }
} 