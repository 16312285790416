.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 90vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 32px;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

.modal-swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

/* 自定义导航按钮样式 */
.swiper-button-next,
.swiper-button-prev {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(255, 255, 255, 0.2);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

/* 自定义分页器样式 */
.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: white;
}

@media (max-width: 768px) {
  .modal-close {
    top: 10px;
    right: 10px;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    width: 32px;
    height: 32px;
  }
  
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px;
  }
} 